import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Title } from '../../components/Title';
import { Wrapper } from '../../components/Wrapper';
import { CardCourseDisplay } from '../courses/components/CardCourseDisplay';

import { toDayDate } from '../../common/formatDateText';
import { ContainerFull } from '../../components/ContainerFull';
import { capitalizarPalabras, firstCapitalLetter } from '../../common/upperCaseWord';


import {
	AdjustmentsHorizontalIcon,
	BriefcaseIcon,
	CalendarIcon,
	MapPinIcon,
	SquaresPlusIcon,
} from '@heroicons/react/20/solid'
import { optionsAllCourseListOpenByIdUser } from '../../redux/actions/options';
import { SkeletonPost } from './skeletons/SkeletonPost';
import { PostCard } from './components/post/PostCard';
import { Tooltip, Zoom } from '@mui/material';

export const HomePage = () => {
	const dispatch = useDispatch();
	const { coursesListByIdUser } = useSelector((state) => state.options);
	const { user } = useSelector((state) => state.user)
	const { _id: userId, firstName, lastName, typeUser, location } = user;

	useEffect(() => {
		dispatch(optionsAllCourseListOpenByIdUser(userId));
	}, [userId, dispatch]);


	return (
		<ContainerFull>
			<div className="w-full px-2">
				<div className="lg:flex lg:items-center lg:justify-between">
					<div className="flex-1">
						<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
							{`Bienvenido ${firstCapitalLetter(firstName)} ${firstCapitalLetter(lastName)}`}
						</h2>
						<div className="mt-1 hidden lg:flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{firstCapitalLetter(typeUser)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(location)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(toDayDate())}
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				typeUser === 'director' && (
					<div className='w-full flex justify-end gap-4 mt-4'>
						{
							typeUser === 'director' && (
								<Tooltip title="Agregar un nuevo post" TransitionComponent={Zoom} placement='top'>
									<button
										type='button'
										// onClick={() => toggleDrawerFilters()}
										onClick={() => { }}
										className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
									><SquaresPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
								</Tooltip>
							)
						}
					</div>
				)
			}
			<div className='flex gap-4'>
				{
					(typeUser === 'estudiante' || typeUser === 'profesor') && (
						<div className='hidden lg:flex lg:w-[40%]'>
							<Wrapper>
								<div className='w-full flex flex-col lg:flex-row gap-4'>
									<div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
										<Title title={'Mis cursos'} />
										<div className="w-full mx-auto">
											{
												coursesListByIdUser.length
													? coursesListByIdUser.map(({
														id,
														headerImage,
														language,
														path, color,
														level,
														status,
														teacher,
														fromDate,
														toDate,
														days,
														hours
													}) => (
														<CardCourseDisplay
															key={id}
															id={id}
															headerImage={headerImage}
															language={language}
															path={path}
															color={color}
															level={level}
															status={status}
															teacher={teacher}
															fromDate={fromDate}
															toDate={toDate}
															days={days}
															hours={hours}
															action={true}
														/>
													))
													: (
														<p className='text-gray-400'>No hay historial de cursos.</p>
													)
											}
										</div>
									</div>
								</div>
							</Wrapper>
						</div>
					)
				}
				{/* <div className={`${typeUser !== "estudiante" || typeUser !== "" ? "w-[100%]" : "w-[60%]"} flex flex-col`}>
					<PostCard
						title="Titulo del post"
						author="Eric Hernandez"
						date="12 de Noviembre del 2024, 16:28:13"
						urlImg="https://cdn.hobbyconsolas.com/sites/navi.axelspringer.es/public/media/image/2017/02/resident-evil-4-caratula.jpg?tf=384x"
						noteText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum enim quae nam porro amet error quos. Ea velit corporis repellendus tempora, sapiente optio eum repudiandae, ut molestiae voluptates iusto quae. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum laboriosam ullam ipsam repellendus ab dolores dolor provident! Tenetur nostrum repudiandae, rerum minus quae enim blanditiis minima sequi magni nemo vero?"
						url="https://cfamex-plataforma.com/login"
					/>
					<PostCard
						title="Titulo del post"
						author="Eric Hernandez"
						date="12 de Noviembre del 2024, 16:28:13"
						urlImg="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj5p53x56crOcna_nqFRw-wIhX8bVtyNQ-vDTdKlhMU2Mu2ccyy_LRBMbU2Z6MQNFCuYNymPTip46pYb0pS1vnIEV1bIJM4EjjPndmoaRUjideUJMpQZswSTO2QKR4JKr0FSQEC29OPrrLq/s1600/3.jpg"
						noteText="Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum enim quae nam porro amet error quos. Ea velit corporis repellendus tempora, sapiente optio eum repudiandae, ut molestiae voluptates iusto quae. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum laboriosam ullam ipsam repellendus ab dolores dolor provident! Tenetur nostrum repudiandae, rerum minus quae enim blanditiis minima sequi magni nemo vero?"
						url="https://cfamex-plataforma.com/login"
					/>
					<SkeletonPost />
				</div> */}
			</div>
		</ContainerFull>
	)
}
